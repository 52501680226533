import React from 'react';
import { Box, Text, Heading } from '@chakra-ui/react';
import { useEffect } from 'react';
const Page = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  return (
    <Box maxW="full" mx="auto" p={4}  bg="	#e9e9e9"   style={{ fontFamily: "Nunito Sans" }}>
          <Heading as="h3" size="xl" mb={4} textAlign="center" fontWeight="300"  style={{ fontFamily: "Nunito Sans" }}>
          Information For 501(c)(3)s

</Heading>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        WHY JOIN?
      </Text>
      <Text mb={4}>
        PhilaFun empowers charities to expand their visibility and generate financial support by honoring their current
        supporters, 24 hours a day/7 days a week. Consistently managing a Campaign on PhilaFun brings more awareness to
        your 501(c)(3) and thus, expands opportunities to capture new Funders! You can even integrate PhilaFun into live
        or virtual events to leverage donations for those in attendance, and even with those who are not. Or when
        launching a capital or comprehensive campaign, use PhilaFun to go “public,” as with any other communication
        medium. At PhilaFun, we believe in promoting your nonprofit and fundraising beyond limits and borders!
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        REGISTRATION
      </Text>
      <Text mb={4}>
        Register your charity and wait for PhilaFun’s Crowdfunding Platform Agreement. Once executed and your
        registration has been approved, and you will be sent a password. Change your password when you login. Go to your
        profile photo to link your Stripe account. You will not be able to post a Campaign without a linked Stripe
        account. Only one account per 501(c)(3) permitted. There may not be an overlap in EINs. Our hope is that small
        and large non-profits have the same opportunity for promotion on PhilaFun. Your charity can earn another post as
        soon as the Campaign Challenges are complete- so the faster you complete the challenges, the faster you can post
        a new Campaign.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        POSTING
      </Text>
      <Text mb={4}>
        Upload a photo/video (less than one minute) and include 100 words of “Why I Fund the Charity” from your Featured
        Funder. Send the Campaign to your Featured Funder so they can register, approve, and launch the Campaign. During
        the Campaign, recognize your Funders on their Wall of Gratitude. After 45 days or once the Campaign Goals are
        complete, your charity can post a new Campaign with a new Featured Funder. Videos can only be less than one
        minute.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        CAMPAIGN FOCUS
      </Text>
      <Text mb={4}>
        Your Campaign should be brief, reflect the purpose and passion of your 501(c)(3), spoken from the words of the
        Featured Funder, and FUN. Be creative in folding your message, giving updates on past successes, and providing a
        purpose and vision for the future. Remember that supporters are now focused on the “cause” rather than the
        “organization” itself.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        FEATURED FUNDER
      </Text>
      <Text mb={4}>
        Your Featured Funder must be over the age of 18. They should be an influential, loyal, financial supporter of
        your 501(c)(3). In addition, they should be tech-savvy and willing to participate on PhilaFun and willing to
        volunteer their time with their Campaign. As a Featured Funder, they will have messaging privileges and are
        strongly encouraged to post thank you messages on any Funders’ Wall of Gratitude.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        WALL OF GRATITUDE
      </Text>
      <Text mb={4}>
        When acknowledging donations and thanking Funders on their pages, remember that for most Funders, it’s about
        being recognized promptly with a personal, heartfelt message, reiterating the purpose and the use of the
        donation.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        CAMPAIGN CHALLENGES
      </Text>
      <Text mb={4}>
        PhilaFun believes in engagement, not a dollar amount. By default, all Campaigns will end in 45 days. Or you can
        try to complete the Campaign Challenge by receiving more than 200 likes, 200 shares, 200 comments, and 200
        donations. The advantage of completing the Campaign Challenge before the 45 days is that it immediately earns
        your 501(c)(3) another Campaign post, allowing you to honor your next Featured Funder.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        FEES
      </Text>
      <Text mb={4}>
        PhilaFun charges a 4.2% administrative processing fee + 0.30 cents on every donation received. This processing
        fee will be deducted from the total amount of the donation and prior to funds being sent to the charity.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        PAYMENT PROCESSOR
      </Text>
      <Text mb={4}>
        Your charity is responsible for registering and maintaining a Stripe account. Charities must register a Stripe
        account and agree to the Terms of Service of Stripe, Inc. Your charity must link their Stripe account to their
        PhilaFun 501(c)(3) profile. Your charity is responsible for any fees or costs of maintaining the Stripe account,
        including but not limited to monthly maintenance fees, withdrawal fees, etc.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        TAX RECEIPTS
      </Text>
      <Text mb={4}>
        Confirm your registered email address and EIN are accurate. When a campaign is complete, a list of Funders to
        the Campaign will be sent to the email address you used to register within 2-3 business days. When applicable,
        we expect the 501(c)(3) will issue tax receipts directly to the Funder(s) within 21 days of the completion of
        the campaign.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        GIFTS/SWAG/PROMOTIONAL ITEMS
      </Text>
      <Text mb={4}>
        Do not offer any swag or promotional items for your Campaign. We cannot calculate the tax deductibility with an
        exchange of goods or services. Do not offer anything in this matter.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        LOGIN
      </Text>
      <Text mb={4}>
        If you would like to register as both a 501(c)(3) and a Funder, please use two different email addresses, and
        remember to login and log off after each session.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        MESSAGING
      </Text>
      <Text mb={4}>
        Funders may receive comments from 501(c)(3)s, Featured Funders, and Philanthropists on their Wall of Gratitude.
        501(c)(3)s may post on any Funder’s Wall of Gratitude if the Funder has made a contribution to their nonprofit.
        Featured Funders and Philanthropists have the privilege of posting on anyone’s Wall of Gratitude.
      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        TEAMWORK
      </Text>
      <Text mb={4}>
        Our collective goal is to have all Funders thanked on their Wall of Gratitude promptly after they make a
        donation. At the minimum, a Funder should be thanked by the 501(c)(3) and when possible, the 501(c)(3)’s
        Featured Funder in the Campaign.
      </Text>
    </Box>
  );
};

export default Page;
