import React from "react";
import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  // useBreakpointValue,
} from "@chakra-ui/react";
import Groupthree from "../points/Pimg/Groupthree.png";

export default function SplitScreen() {
  return (
    <Stack
      minH={"41vh"}
      bg={"linear-gradient(90.62deg, #3BA98B 10.11%, #216C65 106.67%)"}
      direction={{ base: "column", md: "row" }}

      // p={10}
    >
      <Flex
        p={8}
        // ml="20px"
        alignItems="flex-start"
        flex={1}
        align={"center"}
        justify={"center"}
      >
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading
          mt={10}
            fontWeight="600"
            fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}
          >
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              color="white"
              as={"span"}
              
              position={"relative"}
              // _after={{
              //   content: "''",
              //   width: "full",
              //   height: useBreakpointValue({ base: "20%", md: "30%" }),
              //   position: "absolute",
              //   bottom: 1,
              //   left: 0,
              //   zIndex: -1,
              // }}
            >
              Earn points to be recognized by
            </Text>
            <br />{" "}
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              color={"#FFFFFF"}
              as={"span"}
            >
              Charities, Featured Funders and
            </Text>{" "}
            <br />{" "}
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              color={"#FFFFFF"}
              as={"span"}
            >
              Philanthropists!
            </Text>{" "}
          </Heading>
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize={{ base: "md", lg: "lg" }}
            color={"#FFFFFF"}
          >
            Earn points to increase the likelihood of being recognized by
            Charities, Featured Funders, and Philanthropists.
          </Text>
        </Stack>
      </Flex>
      <Flex direction="column-reverse" flex={1}>
        <Image
          width="56.5%"
          ml="30px"
          alt={"Login Image"}
          objectFit={"cover"}
          // height="18vh"
          src={Groupthree}
        />
      </Flex>
    </Stack>
  );
}

