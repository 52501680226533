import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,

} from "@chakra-ui/react";

import Down from "../download/downImg/down.png";
import Gp from "../download/downImg/Gp.png";
import Ap from "../download/downImg/Ap.png";


export default function SplitScreen() {
  return (
    <Stack
      id="Download"
      minH={"90vh"}
      bg=" linear-gradient(180deg, rgba(60, 173, 144, 0) 0%, #3BAC8F 180%)"
      direction={{ base: "column", md: "row" }}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
           <a href="https://www.youtube.com/@philafun">
           <Text
              style={{ fontFamily: "Nunito Sans" }}
              fontWeight="450"
              color="#2F706D"
              as={"span"}
              position={"relative"}
              // _after={{
              //   content: "''",
              //   width: "full",
              //   height: useBreakpointValue({ base: "20%", md: "30%" }),
              //   position: "absolute",
              //   bottom: 1,
              //   left: 0,
              //   zIndex: -1,
              // }}
            >
              Watch Our Demo <br />
                Videos ➡️
            </Text></a>  
            <br />{" "}
          </Heading>
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize={{ base: "md", lg: "lg" }}
            color={"#2F706D"}
          >
            Download the mobile app now or watch demo videos to learn more.
          </Text>
          <Stack
            width="28%"
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            cursor="pointer"
          >
           <a href="https://play.google.com/store/apps/details?id=com.philafun.philafun"><Image src={Gp} /></a> 
          </Stack>
          <Stack
            width="28%"
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            cursor="pointer"
          >
           <a href="https://apps.apple.com/app/philafun/id6448131825"><Image src={Ap} /></a> 
          </Stack>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image h={490} alt={"Login Image"} objectFit={"cover"} src={Down} />
      </Flex>
    </Stack>
  );
}