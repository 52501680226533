import React from "react";
import { Flex, Image, Stack, Box, useBreakpointValue } from "@chakra-ui/react";
import one from "../slide/Simg/one.png";
import two from "../slide/Simg/two.png";
import three from "../slide/Simg/three.png";
import four from "../slide/Simg/four.png";

export default function SplitScreen() {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Stack
      minH={"60vh"}
      bg={"linear-gradient(180deg, rgba(60, 173, 144, 0) 0%, #3BAC8F 100%)"}
      transform="matrix(1, 0, 0, -1, 0, 0)"
      direction={{ base: "column", md: isDesktop ? "row" : "column" }}
    >
      <Flex
        p={1}
        flex={1}
        align={"center"}
        justify={"center"}
        transform="matrix(1, 0, 0, -1, 0, 0)"
      >
        <Stack ml="62px" spacing={6} w={"full"} maxW={"xxl"}>
          {!isDesktop && (
            <Box overflowX="auto" width="100%">
              <Flex justify="space-between" flexWrap="wrap">
                <Image
                  width="80vw"
                  borderRadius="18%"
                  alt={"Login Image"}
                  objectFit={"cover"}
                  src={one}
                />
                <Image width="80vw" alt={"Login Image"} src={three} />
                <Image width="80vw" alt={"Login Image"} src={two} />
                <Image
                  width="80vw"
                  alt={"Login Image"}
                  objectFit={"cover"}
                  src={four}
                />
              </Flex>
            </Box>
          )}
          {isDesktop && (
            <Flex justify="space-evenly">
              <Box width="25%" flexShrink={0}>
                <Image
                  width="100%"
                  mt={10}
                  borderRadius="18%"
                  alt={"Login Image"}
                  objectFit={"cover"}
                  src={one}
                />
              </Box>
              <Box width="26%" flexShrink={0}>
                <Image width="100%" alt={"Login Image"} src={three} />
              </Box>
              <Box width="26%" flexShrink={0}>
                <Image bottom="10" width="100%" alt={"Login Image"} src={two} />
              </Box>
              <Box width="25%" flexShrink={0}>
                <Image
                  width="100%"
                  mt={10}
                  alt={"Login Image"}
                  objectFit={"cover"}
                  src={four}
                />
              </Box>
            </Flex>
          )}
        </Stack>
      </Flex>
    </Stack>
  );
}
