import React from 'react';
import { Box, Text,Heading} from '@chakra-ui/react';
import { useEffect } from 'react';
const Page = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <Box maxW="full" mx="auto" p={4}  bg="	#e9e9e9"   style={{ fontFamily: "Nunito Sans" }}>
        <Heading as="h3" size="xl" mb={4} textAlign="center" fontWeight="300"  style={{ fontFamily: "Nunito Sans" }}>
PhilaFun - Information For Featured Funders/Philanthropists
</Heading>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        WHY JOIN?
      </Text>
      <Text mb={4}>
      Build your philanthropic legacy online on PhilaFun.  The first giving app that recognizes 501(c)(3)s, Funders, and Philanthropists.  Have confidence making your contribution knowing that each charity is vetted prior to posting on the app.  PhilaFun searches for the 501(c)(3) through the United States Internal Revenue Service charitable organizations database, to confirm that they are actively registered with the IRS. 
      </Text>
      <Text mb={4}>
      Help your favorite charities leverage more donations, track your lifetime giving, and join a community that builds philanthropists!  Giving on PhilaFun also demonstrates your commitment to the environment.  PhilaFun provides charities a cost-effective and efficient way to share their mission while reducing their carbon footprint. While many charities organize special events and printed collateral material to showcase and express appreciation for their supporters, PhilaFun does both without the overhead expenses. 

      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        GOALS & PURPOSE
      </Text>
      <Text mb={4}>
      Discover charities and donate to your favorite campaigns.  Earn points with Likes, Comments, Shares, and Donations, to be recognized by 501(c)(3)s, Featured Funders, and Philanthropists.  Earn badges based on your total giving on the app.

      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        POINT SYSTEM
      </Text>
      <Text mb={4}>
      Funders earn one point per: like, comment, share, and donation.  PhilaFun does not guarantee public acknowledgement or recognition by any 501(c)(3), Featured Funder or Philanthropist.  However, 501(c)(3)s, Featured Funders, and Philanthropists will view funders sorted by top point earners first.  Thus, the more points you earn, the more likely you will be acknowledged and recognized.

      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        FUNDER BADGES
      </Text>
      <Text mb={4}>
        A Funder’s badge is based on the amount of total giving on PhilaFun:
      </Text>
      <Box display="flex" alignItems="center" mb={2}>
        {/* <Badge mr={2}>PhilaFunder</Badge> */}
        <Text>PhilaFunder (total giving of $1,000+) one star
</Text>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        {/* <Badge mr={2}>PhilaFan</Badge> */}
        <Text>PhilaFan (total giving of $2,500+) two star
</Text>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        {/* <Badge mr={2}>PhilaFriend</Badge> */}
        <Text>PhilaFriend (total giving of $5,000+) three star
</Text>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        {/* <Badge mr={2}>PhilaFab</Badge> */}
        <Text>PhilaFab (total giving of $25,000+) four star
</Text>
      </Box>
      <Box display="flex" alignItems="center" mb={4}>
        {/* <Badge mr={2}>Philanthropist</Badge> */}
        <Text>Philanthropist (total giving of $100,000+) five star 
</Text>
      </Box>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        REGISTRATION
      </Text>
      <Text mb={4}>
      When you receive an email invitation, download the app and register your account.  Once registered, please wait 24-48 hours for approval.  You will receive an email once you are approved so you can login and approve the Campaign.  When registering your account, be accurate with your personal information: first name, last name, and email.  The information you provide on PhilaFun will be sent to the 501(c)(3)s you donate to. Your first and last name should appear as they do on your tax-return.  

      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        FEES
      </Text>
      <Text mb={4}>
      PhilaFun charges a 4.2% administrative processing fee + 30 cents on every donation received.  This processing fee will be deducted from the total amount of the donation and prior to funds being sent to the charity.  

      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        TIMELINE
      </Text>
      <Text mb={4}>
      Each Campaign will have a 45-day cycle.  The Campaign will also end if it receives more than 200 Likes, 200 Shares, 200 Comments, and 200 Donations.  

      </Text>
     
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        MESSAGING
      </Text>
      <Text mb={4}>
        Funders may receive comments from 501(c)(3)s, Featured Funders, and Philanthropists on their Wall of Gratitude.
        501(c)(3)s may post on any Funder’s Wall of Gratitude if the Funder has made a contribution to their nonprofit.
        Featured Funders and Philanthropists have the privilege of posting on anyone’s Wall of Gratitude throughout the
        app.
      </Text>
       <Text fontSize="xl" fontWeight="bold" mb={4}>
       WALL OF GRATITUDE

      </Text>
      <Text mb={4}>
      As a Featured Funder/Philanthropist, you have the privilege of posting on anyone’s Wall of Gratitude.  Under the charity's profile, you can send your appreciation to "Our Top Funders" of the Campaign . You can help spread the culture of philanthropy by thanking those who support your 501(c)(3) and other Top Funders on the app for their time and commitment for the Love and Fun of Philanthropy.  Your participation will inspire other Funders to follow your philanthropic passions. 

      </Text>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        GIVING HISTORY
      </Text>
      <Text mb={4}>
  Funders with badges as PhilaFab or Philanthropist (cumulative contributions of more than $25,000 on PhilaFun) may request a PDF with their giving history at <a href="mailto:info@philafun.com" style={{ textDecoration: 'underline', color: 'blue' }}>info@philafun.com</a>.
</Text>
    </Box>
  );
};

export default Page;
