import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Drawer,
  Image,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import PhillaFun from "../Nav/BrandImg/PhilaFun.png";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.getElementById("navbar");
      if (nav && window.scrollY > 0) {
        nav.classList.add("scrolled");
      } else if (nav) {
        nav.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 60; // Adjust the offset value as needed
      const elementPosition = element.offsetTop - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
    onClose(); // Close the drawer after clicking on a button
  };

  return (
    <Box
      id="navbar"
      bg="linear-gradient(90.62deg, #3BA98B 10.11%, #216C65 106.67%)"
      px={20}
      py={3}
      position="fixed"
      top={0}
      width="100%"
      zIndex={999}
      transition="background-color 0.3s ease"
      className="navbar"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Text fontSize="xl" fontWeight="bold" color="white" ml="auto">
            <Image
              onClick={() => handleScroll("Home")}
              w={20}
              src={PhillaFun}
            />
          </Text>
          <Flex
            display={{ base: "none", md: "flex" }}
            fontWeight="100"
            ml="360"
            alignItems="center"
            overflowX="auto"
            whiteSpace="nowrap"
          >
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("Home")}
            >
              Home
            </Button>
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("AboutSection")}
            >
              About
            </Button>
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("WorkSection")}
            >
              How it Works
            </Button>
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("Faq")}
            >
              FAQs
            </Button>
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("Download")}
            >
              Demo Videos
            </Button>
          </Flex>
        </Flex>
        <Button
          w="110px"
          color="#2F706D"
          onClick={() => handleScroll("Download")}
          ml="30px"
        >
          Get the app
        </Button>
        <Box display={{ base: "block", md: "none" }}>
          <Button onClick={onOpen} variant="ghost">
            <HamburgerIcon w={6} h={6} />
          </Button>
          <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay>
              <DrawerContent
                borderRadius={20}
                h="40vh"
                w="50vh"
                bg="linear-gradient(90.62deg, #3BA98B 10.11%, #216C65 106.67%)"
              >
                <DrawerCloseButton color="white" />
                <DrawerHeader color="yellow.400" fontWeight={600}>
                  <Image w={20} src={PhillaFun} />
                </DrawerHeader>
                <DrawerBody bg="linear-gradient(90.62deg, #3BA98B 10.11%, #216C65 106.67%)">
                  <Flex direction="column">
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("Home")}
                    >
                      Home
                    </Button>
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("AboutSection")}
                    >
                      About
                    </Button>
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("WorkSection")}
                    >
                      How it works
                    </Button>
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("Faq")}
                    >
                      FAQs
                    </Button>
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("Download")}
                    >
                      Demo Videos
                    </Button>
                  </Flex>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navbar;

