import React from "react";
import { Box, Text,  ChakraProvider } from "@chakra-ui/react";

const TermsOfServicePage = () => {
  return (
    <ChakraProvider>
      <Box
        maxW="full"
        mx="auto"
      
        p={4}
        bg="	#e9e9e9"
        style={{ fontFamily: "Nunito Sans" }}
      >
        <Text fontSize="xl" fontWeight="bold" mb="4">
          PRIVACY POLICY
        </Text>

        <Text mb="4">
          Your privacy is important to us. “We”, at PhilaFun and KITCHANG LLC
          (“us”, “our”), have made a commitment to protect your privacy. This
          privacy policy governs the relationship between you, the “Funder,” or
          the “501(c)(3),” and us. By submitting any personal information
          through our app/site, you are agreeing to the terms of this Privacy
          Policy and consent to the collection of your personal information.
          Personal information may include, but not limited to: your name,
          telephone number, email address, credit card number, etc. It may also
          include any information or content contained during email
          correspondence with us.
        </Text>

        <Text mb="4">
          Other personal information may be derived from your computer: IP
          address, browser type, operating system, time zone/date, location,
          duration, cookies, web beacons, among other data that is collected
          during your time on the app/site. The app/site continuously compiles
          log-in information to monitor traffic and assess activity. The
          information collected from your experiences on PhilaFun is used to
          improve and enhance the functionality of the app/site. PhilaFun does
          not guarantee or warrant the security of any of the information that
          is transmitted through the site or app. It is the sole responsibility
          of the user of the app/site to maintain their password and personal
          account information.
        </Text>
        <Text mb="4">
          By agreeing to the Privacy Policy, you are consenting and
          acknowledging that your personal information is stored and may be
          shared with third parties. You must be 18 years-old or older to use
          the app/site. If you are younger than 18 years-old, we do not
          intentionally collect any information about minors. If you believe
          personal information has been gather on a minor, please contact us at:
          info@philafun.com. If you wish to have your personal information
          deleted from our database and servers, please contact us at:
          info@philafun.com. With the following information: your name, city,
          state, telephone number, email address, and the specific request to
          remove you from our database. When required by law, we reserve the
          right to disclose your information to any law enforcement authorities
          when requested. If an acquisition or partnership occur, you authorize
          us to share your personal information with purchasing party or any
          other the third party involved in the acquisition. This privacy policy
          was last updated on July 2023 and subject to change without notice.
          Please check back before using the app/site to ensure that you
          understand and expressly consent to these terms before participating
          on the app/site.
        </Text>
      </Box>
    </ChakraProvider>
  );
};

export default TermsOfServicePage;
