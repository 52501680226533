import React from "react";
import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  // useBreakpointValue,
} from "@chakra-ui/react";
import groupone from "../work/Wimg/groupone.jpeg";



export default function SplitScreen() {
  return (
    <Stack
      id="WorkSection"
      minH={"41vh"}
      bg={"linear-gradient(90.62deg, #3BA98B 10.11%, #216C65 106.67%)"}
      direction={{ base: "column", md: "row" }}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}>
            <Text
              // ml={10}
              style={{ fontFamily: "Nunito Sans" }}
              fontWeight="600"
              color="white"
              as={"span"}
              position={"relative"}
              // _after={{
              //   content: "''",
              //   width: "full",
              //   height: useBreakpointValue({
              //     base: "20%",
              //     md: "10%",
              //     sm: "5%",
              //   }),
              //   position: "absolute",
              //   bottom: 1,
              //   left: 0,
              //   zIndex: -1,
              // }}
            >
              Discover the charities of top
            </Text>
            <br />{" "}
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              fontWeight={600}
              // ml={10}
              color={"#FFFFFF"}
              as={"span"}
            >
              philanthropists
            </Text>{" "}
          </Heading>
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize={{ base: "md", lg: "lg" }}
            color={"#FFFFFF"}
          >
            Every charity registered on PhilaFun is a United States,
            IRS-approved 501(c)(3). "Give back" with peace of mind knowing that
            your support unlocks the true spirit of philanthropy.
          </Text>
        </Stack>
      </Flex>
      <Flex direction="column-reverse" flex={1}>
        <Image
          style={{ marginLeft: "30px" , borderRadius:"35px" }}
          width="37%"
        
          p={6}
          alt={"Login Image"}
          objectFit={"fill"}
          src={groupone}
        />
      </Flex>
    </Stack>
  );
}

