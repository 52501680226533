import React from "react";
import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  // useBreakpointValue,
} from "@chakra-ui/react";
import grouptwo from "../support/Simage/grouptwo.png";

export default function SplitScreen() {
  return (
    <Stack minH={"40vh"} direction={{ base: "column", md: "row" }}>
      <Flex direction="column-reverse" flex={1}>
        <Image
          width="60.5%"
          ml="10%"
          mb="15px"
          alt={"Login Image"}
          objectFit={"cover"}
          src={grouptwo}
        />
      </Flex>

      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading
            style={{ fontFamily: "Nunito Sans", fontWeight: "600" }}
            fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}
          >
            <Text
              color="#2F706D"
              as={"span"}
              position={"relative"}
              // _after={{
              //   content: "''",
              //   width: "full",
              //   height: useBreakpointValue({ base: "20%", md: "30%" }),
              //   position: "absolute",
              //   bottom: 1,
              //   left: 0,
              //   zIndex: -1,
              // }}
            >
              Support the campaigns you are
            </Text>
            <br />{" "}
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              color={"#2F706D"}
              as={"span"}
            >
              passionate about
            </Text>{" "}
          </Heading>
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize={{ base: "md", lg: "lg" }}
            color={"#323232"}
          >
            Find Campaigns that align with your passions and support your
            favorite Campaigns with Likes, Shares, Comments, and Donations*.
          </Text>
        </Stack>
      </Flex>
    </Stack>
  );
}
