import React from "react";
import {
  Flex,
  Heading,
  Stack,
  Text,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
// import { AiOutlinePlus } from "react-icons/ai";
// import {GrLinkBottom} from "react-icons/gr";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";

export default function SplitScreen() {
  return (
    <Stack
      id="Faq"
      minH={"50vh"}
      bg={"linear-gradient(90.62deg, #3BA98B 10.11%, #216C65 106.67%)"}
      direction={{ base: "column", md: "row" }}
    >
      <Flex p={3} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}>
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              color="white"
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: useBreakpointValue({ base: "20%", md: "30%" }),
                position: "absolute",
                bottom: 1,
                left: 0,
                zIndex: -1,
              }}
            >
              FAQs
            </Text>
          </Heading>
          <Stack spacing={2}>
          <Link to="/faq-one">
            <Button
              color="#2F706D"
              w={{base:"370px",lg:"512px"}}
              style={{ fontFamily: "Nunito Sans" }}
              fontWeight={350}
              rightIcon={<ChevronRightIcon />}
              iconSpacing={2}
              justifyContent="space-between"
            >
              
               FAQs for Funders
                          
              
            </Button>
            </Link>
            <Link to="/faq-two">
           <Button
              color="#2F706D"
              w={{base:"370px",lg:"512px"}}
              style={{ fontFamily: "Nunito Sans" }}
              fontWeight={350}
              rightIcon={<ChevronRightIcon />}
              iconSpacing={2}
              justifyContent="space-between"
            >
           
              FAQs for Featured Funders/Philanthropists
              
            
            </Button>
            </Link>
            <Link  to="/faq-three">
            <Button
              w={{base:"370px",lg:"512px"}}
              color="#2F706D"
              style={{ fontFamily: "Nunito Sans" }}
              fontWeight={350}
              rightIcon={<ChevronRightIcon />}
              iconSpacing={2}
              justifyContent="space-between"
            >
              
              FAQs for 501(c)(3)s
           
            
            </Button>
            </Link>
          </Stack>
        </Stack>
      </Flex>
      <Flex direction="column" flex={1}></Flex>
    </Stack>
  );
}
