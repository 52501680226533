import { Link } from "react-router-dom";
import {
  Box,
  Stack,
  HStack,
  VStack,
 
  Image,
  Text,
} from "@chakra-ui/react";
import { Link as ScrollLink } from "react-scroll";
import PhilaFun from "../Nav/BrandImg/PhilaFun.png";
// import ab from "./footImg/ab.png";
import ae from "./footImg/ae.png";
import af from "./footImg/af.png";
import ad from "./footImg/ad.png";
import Gp from "../download/downImg/Gp.png";
import Ap from "../download/downImg/Ap.png";


const Footer = () => {
  return (
    <Box
      p={{ base: 5, md: 8 }}
      bg="linear-gradient(98.89deg, #3EB293 -4.49%, #246F66 105.75%)"
      maxW="full"
      marginInline="auto"
    >
      <Stack
        spacing={{ base: 8, md: 0 }}
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
      >
        <HStack
          spacing={4}
          d={{ base: "none", sm: "flex" }}
          justifyContent={{ sm: "space-between", md: "normal" }}
        >
          <VStack
            style={{ fontFamily: "Nunito Sans" }}
            spacing={4}
            ml="10px"
            mb="20"
            alignItems="flex-start"
          >
            <Text fontSize="lg" color="#ffffff" fontWeight="bold">
              Quick Links
            </Text>
            <VStack
              style={{ fontFamily: "Nunito Sans" }}
              spacing={2}
              cursor="pointer"
              _hover="blue"
              alignItems="flex-start"
              color="#ffffff"
            >
              <CustomLink to="Home">Home</CustomLink>
              <CustomLink to="AboutSection">About us</CustomLink>
              <CustomLink to="WorkSection">How it Works</CustomLink>
              <CustomLink to="Faq">FAQs</CustomLink>
              <CustomLink to="Download">Demo Videos</CustomLink>
              <a href="https://apps.apple.com/app/philafun/id6448131825"><Image mt={8} width="65%" src={Ap} /></a> 
              <a href="https://play.google.com/store/apps/details?id=com.philafun.philafun"><Image width="65%" src={Gp} /></a> 
            </VStack>
          </VStack>
        </HStack>
        <Box maxW="708px">
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize="lg"
            fontWeight="bold"
            color="#ffffff"
          >
            About Us
          </Text>

          <Image mt="3%" w="100px" src={PhilaFun} alt="s" />

          <Text
            style={{ fontFamily: "Nunito Sans" }}
            mt={5}
            color="#FFFFFF"
            fontSize="md"
          >
           PhilaFun is the first giving app that recognizes 501(c)(3)s, Featured Funders, and Philanthropists. PhilaFun believes in empowering charities with a cost-effective and efficient way to share their mission, showcase their supporters, and raise money. For those who want to make a commitment for the love and fun of Philanthropy, Funders can earn a badge as a Philanthropist to live now, live forever, live their legacy on PhilaFun.
            <br /> *PhilaFun charges a 4.2% administrative processing fee + 30
            cents on every donation received. This processing fee will be
            deducted from the total amount of the donation and prior to funds
            being sent to the charity.
          </Text>
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            mt={5}
            color="#FFFFFF"
            fontWeight={700}
            fontSize="md"
          >
            Get in touch with us at
          </Text>

          <Stack flexDirection="row" mt={5} h="8" W="100%">
            {/* <Image src={ab} /> */}
            <a href="https://www.instagram.com/philafunapp/">
              <Image h="8" src={ad} />
            </a>
            <a href="https://www.facebook.com/thephilafunapp">
              <Image h="8" src={ae} />
            </a>
            <a href="https://www.youtube.com/@philafun">
              <Image h="8" src={af} />
            </a>
          </Stack>
        </Box>
      </Stack>

      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={3}
        justifyContent="space-between"
      >
        <Text
          style={{ fontFamily: "Nunito Sans" }}
          color="#ffffff"
          fontSize="md"
          // ml="10px"
        >
          {" "}
          All rights reserved @PhilaFun
        </Text>
        <Stack spacing={2} direction={{ base: "column", md: "row" }}>
          <Text style={{ fontFamily: "Nunito Sans" }} mr="24px" color="#ffffff">
          <Link to="/termas-a">Terms of Service</Link> | <Link to="/privacy-p">Privacy Policy</Link> | <a>info@philafun.com</a>
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};

const CustomLink = ({ children, to, ...props }) => {
  return (
    <ScrollLink
      to={to}
      smooth={true}
      duration={500}
      offset={-50}
      spy={true}
      exact="true"
      activeClass="active"
      {...props}
    >
      {children}
    </ScrollLink>
  );
};

export default Footer;

