import React from "react";
import { Box, Text, Link, ChakraProvider } from "@chakra-ui/react";
import { useEffect } from 'react';
const Page = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <ChakraProvider>
      <Box
        maxW="full"
        mx="auto"
        p={4}
        bg="	#e9e9e9"
        style={{ fontFamily: "Nunito Sans" }}
      >
        <Text fontSize="xl" fontWeight="bold" mb="4">
          PhilaFun Terms of Service
        </Text>
        <Text mb="4">Date of Last Revision: July 1, 2023</Text>
        <Text mb="4">
          We reserve the right, at our sole discretion, to change or modify
          portions of these Terms of Service at any time and without notice.
          Your continued use of the PhilaFun Platform (the “Platform”)
          constitutes your acceptance of the terms. If you do not wish to accept
          the new Terms of Service, discontinue your use of the Services.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          GENERAL
        </Text>
        <Text mb="4">
          These terms and conditions are effective as of July 2023, and subject
          to change without notice. PhilaFun is operated by KITCHANG LLC,
          located at 2170 Century Park East, Suite 1508, Los Angeles, CA 90067.
          KITCHANG LLC is a California based limited liability corporation with
          a DBA of PhilaFun and PhilaFun.com registered in the City of Los
          Angeles (hereafter, KITCHANG, LLC is referred to as “PhilaFun” or
          “we,” as applicable). By utilizing this app/site, either by browsing,
          visiting, and/or making a donation, you hereby agree and are bound to
          the following terms and conditions, policies and provisions. As
          follows, any reference to “we, us, our” refers to PhilaFun and
          KITCHANG LLC. If you do not agree to with these following terms and
          conditions, policies, and provisions, then please terminate use of
          this app/site immediately. Information provided on this site is for
          reference only. You hereby agree that you are utilizing this
          information on this site/app for your personal reference and at your
          own risk. We reserve the right to update or amend information without
          notice. Carefully read these terms and conditions, policies and
          disclosures before browsing, visiting, participating or making a
          donation. By accessing or using the App, you affirm that you are at
          least 18 years old and capable of entering into a legally binding
          agreement. If you are using the App on behalf of an organization, you
          represent and warrant that you have the authority to bind that
          organization to these Terms. Submit inquiries to{" "}
          <Link color="blue.500" href="mailto:info@philafun.com">
            info@philafun.com
          </Link>
          .
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          ARBITRATION POLICY
        </Text>
        <Text mb="4">
          Any claims and/or disputes that arises between you and PhilaFun and
          its affiliates, officers, members, directors, agents, contractors,
          licensors, employees, information providers, will be determined by
          California state law, to the maximum extent permitted by applicable
          law. When applicable, Federal Arbitration Act and Applicable Federal
          Law will govern these terms. You hereby agree that any and all claims
          and/or disputes will be resolved exclusively by final and binding
          arbitration, held in Los Angeles County, California, before a single
          arbitrator selected by the parties or, if the parties cannot agree,
          then by JAMS, pursuant to its Comprehensive Arbitration Rules and
          Procedures (“JAMS Rules”), which are available on the JAMS website and
          are hereby incorporated by reference.
        </Text>
        <Text mb="4">
          If the arbitration provision applies or you choose arbitration to
          resolve your disputes, then either you or we may start arbitration
          proceedings. You must send a letter requesting arbitration and
          describing your claim to our registered agent. We will contact you and
          attempt to resolve the claim before initiating arbitration. If we are
          unable to reach a resolution within 30 days of the letter, arbitration
          proceedings may be initiated. You and PhilaFun each agree that any
          dispute resolution proceedings will be conducted only on an individual
          basis and not in a class, consolidated, or representative action. You
          agree to waive any right to a jury trial or to participate in a class
          action. You also agree that you have no right to participate in any
          financial recovery or any other relief awarded in any arbitration
          proceeding.
        </Text>
        <Text mb="4">
          You understand and agree that any claims or disputes against PhilaFun
          and its affiliates, officers, members, directors, agents, contractors,
          licensors, employees, information providers, arising out of or related
          to your use of the Platform or these Terms of Service must be filed
          within one (1) year after such claim or cause of action arose or be
          forever barred.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          RELEASE
        </Text>
        <Text mb="4">
          You hereby release, waive, discharge, and covenant not to sue
          PhilaFun, KITCHANG, LLC, their officers, directors, employees, agents,
          and affiliates from any and all claims, liabilities, losses, damages,
          costs, or expenses arising out of or in connection with your use of
          the App. PhilaFun will be released of any direct or indirect claims or
          damages of any dispute that arises between you and any third-party
          users, vendors, servicer, and/or 501(c)(3) from use of the app/site.
          This includes any known or unknown demands or damages that are direct,
          indirect, or special.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          THIRD PARTY SERVICES
        </Text>
        <Text mb="4">
          Links featured on site may not be owned or operated by PhilaFun. We
          are not responsible for the content, services, or products of that
          third-party. We encourage you to review their terms of service and
          privacy policy before engaging in any activity or transaction. Links
          to and from PhilaFun does not imply that we endorse the contents of
          the website or accept the sponsorship or endorsement of those
          third-parties. Links provided are for reference only. If you
          communicate with third parties, you agree to have your information
          collected. When appropriate, we reserve the right to identify you as
          the person who has contacted them. You agree that we may communicate
          with you about matters related to your account by SMS, MMS, text
          message or other electronic means to your mobile device. Message and
          data rates may apply. Further, when setting up your PhilaFun account,
          if you click “Send code” by “Text Message,” you agree to receive
          automated text messages related to your account from or on behalf of
          PhilaFun at the phone number provided.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          DATA PRIVACY AND SECURITY
        </Text>
        <Text mb="4">
          We do not accept any liability of information that is lost, misused,
          accessed, disclosed, altered or destroyed by a third-party. We use a
          number of third-party service providers including but not limited to:
          a third-party credit card merchant to collect your payment, a
          third-party vendor to host the app/site, and third party
          collaborations with 501(c)(3)s. We are not responsible for the actions
          made by these third parties. Even if you have chosen to signup
          anonymously, we do not accept any liability for loss and/or damage
          that you may suffer as a result of this third party’s acts and/or
          omission. There are risks associated with electronic transmission of
          personal information. By agreeing to these Terms of Service, you
          consent to these risks.
        </Text>
        <Text mb="4">
          PhilaFun has no obligation to retain any information or data relating
          to any account. PhilaFun reserves the right to delete data or to
          terminate accounts or Campaigns at any time and for any reason, with
          or without notice, and without any liability to you or to any third
          party for any claims or damages. You authorize PhilaFun, the right to
          provide any information to law enforcement or other regulatory
          authorities, and to assist in any investigation thereof.
        </Text>
        <Text mb="4">
          By agreeing to these Terms of Service, you agree that you have
          reviewed and consent to the Privacy Policy attached hereto as Schedule
          A (the “Privacy Policy”).
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          DISCLAIMER AND WARRANTY
        </Text>
        <Text mb="4">
          All the services provided on this website are on an “as-is,” “as
          available,” or “with all faults” basis, without warranty, of any kind,
          express or implied, oral or written, and disclaims all implied
          warranties. All terms and disclaimers on the site are to benefit
          PhilaFun and its affiliates, officers, members, directors, agents,
          contractors, licensors, employees, and information providers. We do
          not guarantee that the App will be error-free, secure, or
          uninterrupted. You use the App at your own risk.
        </Text>
        <Text mb="4">
          We do not guarantee or warrant that the use of this site is 100%
          secure. Use of this site is at your own risk and you assume full
          responsibility. To the maximum extent permitted by law, we disclaim
          any warranties or representations, and we will not be liable for any
          damages of any kind related to your use of this app/site. PhilaFun and
          its affiliates, officers, members, directors, agents, contractors,
          licensors, employees, or information providers will not be liable for
          any injury, loss, claim, and/or demands.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          INDEMNIFICATION
        </Text>
        <Text mb="4">
          You agree to defend, indemnify, and hold harmless PhilaFun and its
          affiliates, officers, members, directors, agents, contractors,
          licensors, employees, and information provider from and against any
          and all liability, claims, and costs, including, without limitation,
          attorney’s fees, arising from your breach or alleged violations of
          these Terms of Service and /or the documents they incorporate by
          reference, or your violation of any law or trademark, copyright, or
          other intellectual property rights of a third-party. If you reside in
          California, you waive California Civil Code Section 1542, if you
          reside outside of California, you waive any similar codes or
          doctrines.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          LIMITATION OF LIABILITY
        </Text>
        <Text mb="4">
          PhilaFun will not be liable for the direct, indirect, incidental,
          special, punitive, consequential damages or losses arising out of, or
          in any way connected to these terms of service, content of the
          site/app, and/or the services that are directly or indirectly
          expressed or implied to perform, or any use of the App. This includes
          injury, loss, or damage from the app/ site. The total liability, in
          tort, breach of contract, or damages or losses arising out of this
          site or app, terms of service, or transaction will be limited to the
          Fees you have paid, if any, to us for the use of the Platform in the
          twelve (12) months preceding the event giving rise to the liability.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          INTELLECTUAL PROPERTY
        </Text>
        <Text mb="4">
          All content and features on PhilaFun are registered or unregistered
          trademarks, logos, images, photographs and graphics and owned by
          PhilaFun and KITCHANG LLC. This includes the app/site design and
          layout, programming code, etc. Copying, imitating or forwarding these
          items on the app/site are strictly forbidden. Contents of this
          app/site are for personal reference only. You are not authorized to
          copy or edit any of the intellectual property from the app/site
          including source code within the app/site.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          SUGGESTIONS/FEEDBACK
        </Text>
        <Text mb="4">
          PhilaFun will not provide any compensation for any content,
          suggestions, or feedback you provide. Any content, suggestions, or
          feedback that is conveyed from your verbal or written correspondence
          becomes the sole property of PhilaFun. We have the right to process
          and compile the information you send to us in any way without any
          restriction or monetary obligation to you.
        </Text>
        <Text mb="4">
          All content and features on PhilaFun are registered or unregistered
          trademarks, logos, images, photographs and graphics and owned by
          PhilaFun and KITCHANG LLC. This includes the app/site design and
          layout, programming code, etc. Copying, imitating or forwarding these
          items on the app/site are strictly forbidden. Contents of this
          app/site are for personal reference only. You are not authorized to
          copy or edit any of the intellectual property from the app/site
          including source code within the app/site.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          TERMINATION
        </Text>
        <Text mb="4">
          We reserve the right to suspend or terminate your Campaign or your use
          and access as a funder, featured funder, philanthropist, or 501(c)(3)
          to our app and site without notice or reason.
        </Text>
        <Text mb="4">
          ACCESS AND USE OF THE SERVICES BY FUNDERS (Applicable to Individual
          Users Accessing Platform on Their Own Behalf)
        </Text>
        <Text mb="4">
          The Services Description: The services offered on this platform allow
          charities that have established their tax-exempt status under Internal
          Revenue Code Section 501(c)(3) (each, a “501(c)(3)”) to post a
          campaign (“Campaign”) to accept monetary donations (“Donations”) from
          users (“Funders”) on behalf of the beneficiary of the 501(c)(3)
          (“Beneficiary”) and for Funders to make those donations.
        </Text>
        <Text mb="4">
          PhilaFun is not a broker, agent, financial institution, creditor or
          501(c)(3). PhilaFun is an intermediary, technology platform that
          allows 501(c)(3)s to connect with potential Funders to raise money for
          their 501(c)(3). An administrative processing fee of 4.2% + $0.30 is
          charged per donation. This processing fee will be deducted from the
          total amount of the donation and prior to funds being sent to the
          charity.You are not authorized to use the Platform if you are under 18
          years old.
        </Text>
        <Text mb="4">
          All Donations are at your own risk. It is your responsibility to
          understand the 501(c)(3)s you are supporting and what the funds will
          be generally used for. Check the 501(c)(3)’s profile page or public
          website for more information. Funders are to engage in their own
          research of the charity prior to making a donation.
        </Text>
        <Text mb="4">
          Consult your tax, legal, financial or other professional advisor on
          the tax deductibility of your gift. PhilaFun makes no representation
          as to whether any portion of your donations, including, if any,
          administrative and/or transaction fees, are tax deductible. PhilaFun
          will not have any liability for any claim by any federal, state, local
          or any other tax authority on any applicable tax return of any
          donation by you, any funder or any 501(c)(3).
        </Text>
        <Text mb="4">
          Funders who have a badge as a PhilaFab or Philanthropists (total
          giving of over $25,000 on PhilaFun) may request a giving report for
          their contributions at info@philafun.com.
        </Text>
        <Text mb="4">
          PhilaFun does not endorse any specific 501(c)(3) nor does PhilaFun
          engage in any solicitation activities. By using the Services, you
          understand and agree that PhilaFun is not responsible for the use of
          Donations or the amount of funds raised. We do not and cannot verify
          the information that 501(c)(3) provides, nor do we represent or
          guarantee that the donations will be used in accordance with any
          fundraising purpose prescribed by the 501(c)(3) in accordance with
          applicable laws. Possible fraudulent activity and the misuse of funds
          should be reported to the Charities Bureau of the Office of the
          Attorney General for California.
        </Text>
        <Text mb="4">
          PhilaFun is not responsible for any offers, promises, rewards or
          promotions made or offered by 501(c)(3)s; such conduct and activity
          violates these Terms of Service.
        </Text>
        <Text mb="4">
          In order to contribute to a 501(c)(3), a Funder will be required to
          provide PhilaFun a valid credit card number that is linked to the
          Funder’s PhilaFun account. You, as a Funder, represent and warrant to
          PhilaFun that such information is true, current and accurate and that
          you are authorized to use the credit card. You are responsible for any
          overdraft fees or charges that may be imposed by your bank or card
          issuer if your contribution exceeds the amount available. In the event
          that our payment processor refuses to accept or revoke acceptance of
          your contribution, you agree to pay all amounts due upon demand, and
          will be subject to a late fee of 1% per month or the maximum amount
          permitted by law.
        </Text>
        <Text mb="4">
          You agree that a certain minimum or maximum donation amount may apply,
          and that all donations are final and will not be refunded unless
          PhilaFun, in its sole discretion, agrees to issue a refund. Due to the
          volume of transactions on the platform, no refunds or transfers
          requests to support another nonprofit will be permitted. All donations
          are made in good-faith. Whether you claim a refund before or after a
          Campaign ends, we will not refund any amount nor have the authority to
          request a refund or chargeback from the 501(c)(3). If you make payment
          by credit or debit card, and our payment processor refuses to accept
          or revokes acceptance, you agree to pay all amounts due to us.
        </Text>
        <Text mb="4">
          PhilaFun uses third-party payment processing partner to process your
          transaction. At no time does PhilaFun possess or control (legally or
          physically) any Donations. All Donations will be transferred directly
          to Charity, less the Fees, which will be transferred directly to
          PhilaFun. Once a Campaign ends, your information will be shared with
          the 501(c)(3) who will send you a tax-receipt to the extent required
          by law. Funders acknowledge that by contributing a Donation to a
          Campaign, the Funder agrees to the processing, use, transfer or
          disclosure of data by the payment processor and 501(c)(3) pursuant to
          these Terms of Service as well as any and all applicable terms set
          forth by our payment partners.
        </Text>

        <Text mb="4">
          You acknowledge and agree that your personal information (not
          including any credit card numbers) will be shared with the 501(c)(3)
          to which you make a donation, including without limitation, to
          facilitate donation tax-receipts issued by the 501(c)(3). PhilaFun is
          not responsible, and shall not be liable, for any 501(c)(3)’s use of
          this information.
        </Text>
        <Text mb="4">
          You agree that should your contribution be declined or not accepted by
          the 501(c)(3) of your choice, your contribution will be refunded to
          you.
        </Text>
        <Text mb="4">
          PhilaFun shall not be liable for any delays or failures to perform the
          services provided by PhilaFun such as acts of God, war or threats of
          war, terrorism or other unpredictable unforeseen environmental and
          miscellaneous hazards.
        </Text>
        <Text mb="4">
          Although PhilaFun was created with the intention to exist in
          perpetuity, you understand and recognize that unforeseen circumstances
          may lead to the alteration, consolidation or termination of this
          app/site.
        </Text>
        <Text mb="4">
          Do not to use the app/site to transmit or upload any content that
          infringes on any intellectual property that you do not have a right to
          upload which contains software viruses or malware or that may violate
          anyone’s privacy.
        </Text>
        <Text mb="4">
          Your solicited and unsolicited information and content you provide on
          the app/site is public information (not including credit card
          information). Information that you enter in connection with your
          profile is publicly displayed. Your information may be publicly appear
          on other search domains or sites including general web searches.
        </Text>
        <Text mb="4">
          Funders, Featured Funders and Philanthropists, earn one point per:
          like, comment, share, and donation.
        </Text>

        <Text mb="4">
          PhilaFun does not guarantee recognition or written remarks by any
          501(c)(3), featured funder or philanthropist. 501(c)(3)s, featured
          funders, and philanthropists will view funders sorted by top point
          earners first. But we do not guarantee any acknowledgement shall be
          made regardless of the number of points you generate on the app.
        </Text>
        <Text mb="4">
          You agree that you are solely responsible for your interactions with
          any other Funder, Featured Funder, and/or Philanthropist. PhilaFun
          will have no liability or responsibility with respect thereto.
          PhilaFun reserves the right, but has no obligation, to become involved
          in any way with disputes between you and any other party on the app.
        </Text>
        <Text mb="4">
          You are responsible for maintaining the confidentiality of your
          password and account. You are fully responsible for any and all
          activities that occur under your password or account. Notify PhilaFun
          of any unauthorized use of your password or account or any other
          breach of security. Sign out from your account at the end of each
          session when accessing the Services.
        </Text>
        <Text mb="4">
          PhilaFun encourages you to maintain a copy of all electronic and other
          records related to the Campaigns you contribute to.
        </Text>
        <Text mb="4">
          ACCESS ON BEHALF OF CHARITIES (Applicable to Users Accessing Platform
          on Behalf of a 501(c)(3))
        </Text>
        <Text mb="4">
          As an individual registering a 501(c)(3) to use this platform, you, on
          behalf of the 501(c)(3) organization, represent, warrant, and covenant
          all information you provide in connection with the Campaign is
          accurate and true. You affirm you are a duly authorized representative
          or designee of the 501(c)(3), authorized to represent and raise funds
          for the 501(c)(3) and bind the 501(c)(3) to these Terms of Service.
        </Text>
        <Text mb="4">
          By registering on PhilaFun on behalf of your 501(c)(3), your 501(c)(3)
          agrees to services of the app/website provided by PhilaFun and agrees
          to partner as a platform charity on PhilaFun. The 501(c)(3) hereby
          appoints PhilaFun as its non-exclusive agent for the specific and
          limited purpose of facilitating charitable contributions for the
          501(c)(3).
        </Text>
        <Text mb="4">
          You agree on behalf of the 501(c)(3), for PhilaFun to charge an
          administrative processing fee of 4.2% + $0.30 per donation (the “Fee”)
          for the services conducted by PhilaFun through the Platform. You
          understand that this processing fee will be deducted from the total
          amount of the donation and prior to funds being sent to the charity.
        </Text>
        <Text mb="4">
          PhilaFun does not hold or control any funds. When a donation is made,
          PhilaFun deducts 4.2% + 30 cents from the contribution and then the
          remaining amount is transferred directly to the charity's Stripe
          account.
        </Text>

        <Text mb="4">
          You are raising funds for a 501(c)(3), with a cause or activity that
          is legal under all applicable federal, state, local laws and
          regulations. PhilaFun reserves the right to decline the registration
          request from any nonprofit. PhilaFun strictly prohibits any lobbying
          on the app. Any political organizations, activities, or causes will be
          declined. Any religious organizations deemed as “extreme” by PhilaFun
          in its sole discretion may also be declined. Registration data and
          certain other information about you are governed by these Terms of
          Service and Privacy Policy.
        </Text>
        <Text mb="4">
          Shall PhilaFun discover any information you provided about yourself,
          your 501(c)(3), or the purpose of your Fundraiser is incorrect or
          violates any of these Terms of Service, your access to the Services
          may be immediately suspended, terminated, and expelled from the
          app/site and fines may be applied by relevant authorities, which will
          in all such cases be payable by you.
        </Text>
        <Text mb="4">
          PhilaFun may, in its sole discretion, place a hold on the 501(c)(3)’s
          PhilaFun account or Campaign. These actions may be taken as we
          investigate what may have been reported to us as misleading or
          fraudulent, and/or has violated our Terms of Services.
        </Text>
        <Text mb="4">
          As a representative of the 501(c)(3), you are responsible for ensuring
          that the information you provide to PhilaFun and the payment
          processor, is accurate and up-to-date. PhilaFun may, at any time, for
          any reason, without notice, and in its sole discretion, issue a refund
          with or without consulting with you. PhilaFun is not liable to you or
          to any third party for any claims, damages, costs, losses, or other
          consequences caused by PhilaFun issuing refunds, including, but not
          limited, to transaction or overdraft fees.
        </Text>
        <Text mb="4">
          You agree to immediately notify PhilaFun at info@philafun.com and
          cease the use of the site/app if your 501(c)(3) has disbanded or
          dissolved. You agree to terminate the use of the site/app on behalf of
          your 501(c)(3) if you have been released of your duties or resigned
          from your role at the 501(c)(3).
        </Text>
        <Text mb="4">
          Your 501(c)(3) agrees to monitor and track donations by State to
          determine whether your 501(c)(3) needs to complete a charitable
          solicitation registration with one or multiple states. You are
          responsible for compliance and adherence of these regulations. You are
          responsible and agree to any and all administrative fees and/or legal
          expenses incurred, if any, should one or multiple States determine
          that your fundraising activity in their jurisdiction require an
          application or registration of any kind.
        </Text>
        <Text mb="4">
          All donations will be used solely for the purpose you have stated on
          and in connection with your Campaign. All donations contributed to
          your Campaign will be used solely as described in the materials that
          you described in furtherance of your 501(c)(3)’s mission.
        </Text>
        <Text mb="4">
          You will not provide or offer to provide goods or services in exchange
          for Donations. You are not permitted to offer any contest, reward,
          exchanges, raffles or similar activity.
        </Text>
        <Text mb="4">
          PhilaFun uses third-party to process Donations. You are responsible
          for any administrative, overdraft fees or charges that may be imposed
          by the third payment processor.
        </Text>
        <Text mb="4">
          The 501(c)(3) shall receive their funds (minus PhilaFun’s
          administrative processing and credit card processing fee) through the
          payment processor. The list of Funders will be sent approximately 2-3
          days of the completion of the Campaign. PhilaFun disclaims any delays
          with the withdrawals or timeliness of this information.
        </Text>
        <Text mb="4">
          A list of funders (including names, email addresses, donation amounts,
          and the date of transaction) will be sent to the 501(c)(3) at the
          completion of the campaign. The list of funders is provided “as-is”,
          and PhilaFun does not warrant or guarantee the accuracy of the list of
          Funders. PhilaFun expects you (the Charity) will follow up with a tax
          receipt within a month after the Campaign ends.
        </Text>
        <Text mb="4">
          It is your responsibility to determine what, if any, taxes apply to
          the donations you receive through your use of the app/site. It is
          solely your responsibility to assess, collect, report or remit the
          correct tax, if any, to the appropriate tax authority.
        </Text>
        <Text mb="4">
          PhilaFun does not withhold funds for tax purposes or otherwise.
          Charities will be solely responsible for taxes based on applicable
          international, federal, state, local or any other taxes or levies, or
          for any applicable taxes based on their net income or gross receipts
          (if any).
        </Text>
        <Text mb="4">
          You will comply with all relevant and applicable laws and financial
          reporting obligations, including but not limited to, laws and
          obligations relating to registration, tax reporting, contributions,
          and asset disclosures for your Campaign. You agree to issue the
          necessary tax-receipts and paperwork directly to your Funders within
          21 days of receiving your list of funders to the extent required by
          law. Violation of this term may result in the termination of your
          501(c)(3)’s use of the site/app. While PhilaFun will comply with its
          own requirement, PhilaFun does not issue tax receipts on behalf of
          your 501(c)(3).
        </Text>
        <Text mb="4">
          You warrant that the Featured Funder that you are showcasing is the
          true identify of that individual. Your Featured Funder is 18 years old
          or older. Your Featured Funder, their officers, agents and employees
          are agreeing that, they are granting PhilaFun permission to reproduce
          their name, likeness, identity, voice, photographic image,
          videographic image and oral or recorded statements in any publication
          or endorsement for PhilaFun. They waive and release PhilaFun and its
          officers, agents and employees, from any claim or liability. He/she
          understands that no compensation or remuneration of any kind from
          PhilaFun will be related to this permission and release or the
          materials covered by this permission and release. 501(c)(3)s, Featured
          Funders and Philanthropists volunteer their time when posting on the
          Walls of Gratitude, and no compensation or remuneration of any kind
          from PhilaFun will be available. PhilaFun reserves the right to
          contact any Featured Funder/Philanthropist directly to confirm their
          true identity.
        </Text>

        <Text>
          501(c)(3)s, Featured Funders, and Philanthropists are encouraged to
          inspire the PhilaFun community by commenting on a Funders’ Wall of
          Gratitude. The Wall of Gratitude is a feature for 501(c)(3)s, Featured
          Funders, and Philanthropists to thank Funders in an appreciative
          manner. 501(c)(3)s may post on any Funder’s Wall of Gratitude if they
          Funder has made a contribution to their nonprofit. Featured Funders
          and Philanthropists have the privilege of posting on anyone’s Wall of
          Gratitude throughout the app. You must engage in respectful and
          uplifting acknowledgements to continue with this privilege. We reserve
          the right to suspend or terminate any messaging privileges if there
          are any prohibited activity including defamatory, abusive, profane,
          threatening, offensive, or illegal materials.
        </Text>
        <Text mb="4">
          501(c)(3)s, Featured Funders, and Philanthropists are prohibited from
          promoting or sharing external links, events, organizations, or
          anything of similar extent on a Funder’s Wall of Gratitude. Sharing or
          the exchange of any personal information is also prohibited, and the
          Wall of Gratitude is not to be used for any additional implicit or
          explicit solicitation.
        </Text>
        <Text mb="4">
          Modifications to the Services: PhilaFun reserves the right to modify,
          suspend or discontinue, temporarily or permanently, the Services (or
          any part thereof) at any time and for any reason, with or without
          notice, and without any liability to you or to any third party for any
          claims, damages, costs, or losses resulting therefrom.
        </Text>

        <Text fontSize="lg" fontWeight="bold" mb="4">
          PAYMENT PROCESSOR
        </Text>
        <Text mb="4">
          PhilaFun uses the payment processor, Stripe, Inc. to process
          transactions. When PhilaFun receives a donation, it will be sent to
          the charity, but PhilaFun will appear credit card statement.
        </Text>
        <Text mb="4">
          PhilaFun does not hold or control any funds. When a donation is made,
          PhilaFun deducts the processing fee of 4.2% + 30 cents from the
          contribution and then the remaining amount is transferred directly to
          the charity's Stripe account.
        </Text>
        <Text mb="4">
          Your charity is responsible for registering and maintaining a Stripe
          account to receive and withdraw your donations. Charities must
          register a Stripe account and agree to the Terms of Service of Stripe,
          Inc. Your charity is responsible for any charges, fees, or costs of
          maintaining the Stripe account including but not limited to monthly
          maintenance fees, withdrawal fees, etc.
        </Text>
        <Text mb="4">
          As a 501(c)(3), in order to withdraw funds from your account, you will
          be required to provide them information regarding you organization
          including bank account information. On behalf of the 501(c)(3), you
          represent and warrant to PhilaFun that such information is true and
          that you are authorized to withdraw from the account.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          CALIFORNIA CONSUMER PRIVACY ACT
        </Text>
        <Text mb="4">
          Laws (California Consumer Privacy Act, California Consumer Privacy Act
          of 2018) in California and other states provide users with certain
          rights regarding their personal information, including the right to
          access, delete, correct and/or opt-out of the “sale” or "sharing" of
          their personal information. California residents have rights to
          request access to certain personal information, correction of
          inaccuracies in personal information, and deletion of their personal
          information, subject to certain exceptions, and may not be
          discriminated against because they exercise any of their rights under
          the CCPA in violation of Cal. Civ. Code §1798.125.
        </Text>
        <Text mb="4">
          You also have the right to opt-out of PhilaFun selling or sharing your
          information as defined under the CCPA. To opt out of the “sale” or
          “sharing” of personal information through tracking technologies,
          please email info@philafun.com with the subject line: Do Not Share. In
          the event of any conflict between the Privacy Policy and the CCPA, the
          terms of the CCPA shall prevail.
        </Text>
      </Box>
    </ChakraProvider>
  );
};

export default Page;
