import React from "react";
import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  
} from "@chakra-ui/react";
import Groupfour from "../commitment/cImg/Groupfour.png";

export default function SplitScreen() {
  return (
    <Stack
      minH={"40vh"}
      //   bg={'linear-gradient(90.62deg, #3BA98B 10.11%, #216C65 106.67%)'}
      direction={{ base: "column", md: "row" }}
    >
      <Flex direction="column-reverse" flex={1}>
        {/* second image */}
        <Image
          width="60.5%"
          mb="15px"
          ml="10%"
          alt={"Login Image"}
          objectFit={"cover"}
          src={Groupfour}
        />
      </Flex>

      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
        <Heading fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}>
  <Text
    style={{ fontFamily: "Nunito Sans" }}
    fontWeight="600"
    color="#2F706D"
    as={"span"}
  >
    Make a commitment to lifelong
  </Text>
  <br />{" "}
  <Text
    style={{ fontFamily: "Nunito Sans" }}
    fontWeight="600"
    color={"#2F706D"}
    as={"span"}
  >
    giving and become a
  </Text>{" "}
  <Text
    style={{ fontFamily: "Nunito Sans" }}
    fontWeight="600"
    color={"#2F706D"}
    as={"span"}
  >
    philanthropist.
  </Text>{" "}
</Heading>

          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize={{ base: "md", lg: "lg" }}
            color={"#2F706D"}
          >
            Track your progress towards lifelong charitable giving as a
            PhilaFunder, PhilaFan, PhilaFriend, PhilaFab, and ultimately, a
            Philanthropist. Secure your philanthropic legacy now and help
            inspire the next generation of philanthropists all on PhilaFun.
          </Text>
        </Stack>
      </Flex>
    </Stack>
  );
}
