import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Image,
} from "@chakra-ui/react";
import PhilaFun from "../Nav/BrandImg/PhilaFun.png";

export default function About() {
  return (
    <Container style={{ overflowX: "visible" }} id="AboutSection" maxW={"7xl"}>
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontSize={{ base: "3xl", sm: "3xl", md: "3xl" }}
          lineHeight={"110%"}
        >
          {" "}
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            as={"span"}
            color={"#2F706D"}
        
            fontWeight="700"
          >
            About Us
          </Text>
        </Heading>
        <Stack spacing={6} direction={"row"}>
          <Button
            px={12}
            py={8}
            lineHeight={"80%"}
            color="#DEC776"
            colorScheme={"#DEC776"}
            bg="linear-gradient(90.62deg, #3BA98B 10.11%, #216C65 106.67%)"
            // _hover={{ bg: 'orange.500' }}
          >
            <Image width="100px" src={PhilaFun} />
          </Button>
        </Stack>

        <Text
          style={{ fontFamily: "Nunito Sans" }}
          color={"#2F706D"}
          maxW={"4xl"}
        >
         PhilaFun is the first giving app that recognizes 501(c)(3)s, Featured Funders, and Philanthropists. PhilaFun believes in empowering charities with a cost-effective and efficient way to share their mission, showcase their supporters, and raise money. For those who want to make a commitment for the love and fun of Philanthropy, Funders can earn a badge as a Philanthropist to live now, live forever, live their legacy on PhilaFun.
        </Text>
        <Heading
          fontWeight={500}
          fontSize={{ base: "8xl", sm: "3xl", md: "3xl" }}
          lineHeight={"210%"}
        >
          {" "}
        </Heading>
      </Stack>
      <Text
        as={"span"}
        style={{ fontFamily: "Nunito Sans" }}
        fontWeight={700}
        fontSize={{ base: "3xl", sm: "3xl", md: "3xl" }}
        ml="12px"
        color={"#2F706D"}
      >
        How it works
      </Text>
    </Container>
  );
}
